<template>
  <div data-app>
    <v-data-table
      v-model="selected"
      :dense="dense ? dense : false"
      :headers="headers"
      :items="items"
      :items-per-page="itemsPerPage ? itemsPerPage : 20"
      :class="tableClass ? tableClass : ''"
      :search="search"
      :sortBy="sortBy ? sortBy : ''"
      :item-key="itemKey"
      :hide-default-header="hideHeader ? hideHeader : false"
      :hide-default-footer="hideFooter ? hideFooter : true"
      :show-group-by="false"
      :show-select="showSelect ? showSelect : false"
      :loading="loading"
      :multi-sort="multiSort ? multiSort : false"
      :loading-text="loadingText ? loadingText : 'Cargando... Por favor espere'"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
      }"
      style="margin-bottom: 10px"
    >
      <template v-slot:top>
        <v-row class="mx-0 my-2">
          <v-row style="margin: 0px; padding-left: 10px">
            <label style="margin: 0px; width: auto">{{
              $t('Components.AdminSimpleList.orderText')
            }}</label>
            <multiselect
              style="width: auto; min-width: 100px; padding-left: 10px"
              track-by="id"
              label="name"
              deselect-label=""
              select-label=""
              :placeholder="internalOrder.name"
              v-model="internalOrder"
              @input="$emit('orderEvent', internalOrder)"
              :options="orderOptions"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="false"
              :allow-empty="false"
              :preserve-search="false"
            />
          </v-row>
          <v-spacer />
          <span style="padding: 0px 10px"
            ><strong style="font-size: 15px"
              >{{ $t('Components.AdminSimpleList.totalResults') }}
              {{ totalItems }}</strong
            >
          </span>
        </v-row>
      </template>
      <template v-slot:[`item.actions`]="{ item }" style="width: 100px">
        <v-tooltip min-width="auto" top v-for="buttom in extraButtoms" :key="buttom.id">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-on="on"
              v-bind="attrs"
              class="mr-1"
              :title="buttom.text"
              @click="handleRedirect(buttom, item)"
            >
              {{ buttom.icon }}
            </v-icon>
          </template>
          <span>{{ buttom.text }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="mr-1"
              v-if="showEdit ? showEdit : true"
              @click="editItem(item)"
            >
              {{ editIcon && editIcon !== '' ? editIcon : 'mdi-pencil' }}
            </v-icon>
          </template>
          <span>
            {{
              editTooltip && editTooltip !== ''
                ? editTooltip
                : $t('Components.AdminSimpleList.editText')
            }}</span
          >
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              v-if="showDelete ? showDelete : false"
              @click.prevent="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>{{ $t('Components.AdminSimpleList.deleteText') }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`header.actions`]>
        {{ $t('Components.AdminSimpleList.actionsText') }}
      </template>
      <template v-slot:no-data
        ><div class="text-center" />
        {{ $t('Components.AdminSimpleList.noData') }}
      </template>
      <template v-slot:no-results
        ><div class="text-center" />
        {{ $t('Components.AdminSimpleList.noResults') }}
      </template>
      <template v-slot:loading
        ><div class="text-center" />
        {{ $t('Components.AdminSimpleList.loadingData') }}
      </template>
    </v-data-table>
    <div class="text-center pt-2" style="margin: 10px 0px">
      <v-pagination
        @input="sendCurrentPage($event)"
        color="#0c2c7c"
        v-model="currentPage"
        :length="pages"
      />
    </div>
  </div>
</template>
<script>
  import 'sweetalert2/dist/sweetalert2.min.css'
  export default {
    props: [
      'headers',
      'items',
      'loading',
      'loadingText',
      'sortBy',
      'multiSort',
      'tableClass',
      'dense',
      'showGroupBy',
      'hideHeader',
      'hideFooter',
      'showSelect',
      'totalItems',
      'itemsPerPage',
      'page',
      'pages',
      'extraButtoms',
      'showCreate',
      'showDelete',
      'itemKey',
      'orderOptions',
      'orderBy',
      'showEdit',
      'editIcon',
      'editTooltip',
    ],
    components: {},
    data() {
      return {
        internalOrder: this.orderBy,
        search: '',
        selected: [],
        item: [],
        dialogDelete: false,
        currentPage: this.page,
      }
    },
    watch: {
      orderBy(newValue) {
        this.internalOrder = newValue
      },
    },
    methods: {
      handleRedirect(buttom, item) {
        if (buttom.typeLink == 'name') {
          this.$router.push({
            name: buttom.link,
            params: { id: item.id },
          })
        } else {
          this.$router.push({ path: buttom.link, query: { id: item.id } })
        }
      },

      editItem(item) {
        this.$emit('edit:item', item)
      },

      deleteItem(item) {
        this.$swal({
          title: '¿Estás seguro?',
          text: 'Eliminarás esta información de forma permanente',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Si, eliminar',
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal({
              title: '¡Confirmado!',
              text: 'El registro será eliminado',
              icon: 'success',
            })
            this.$emit('delete:item', this.item)
          }
        })
        this.item = Object.assign({}, item)
      },

      sendCurrentPage(page) {
        this.$emit('update:page', page)
      },
    },
  }
</script>

<style lang="scss">
  @import '~sweetalert2/src/variables';
  @import '~sweetalert2/src/sweetalert2';
  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.02);
  }
  .multiselect__tags {
    height: 25px;
    min-height: 25px;
  }
  .multiselect__select {
    height: 25px;
  }
  .multiselect {
    height: 25px;
  }
</style>
